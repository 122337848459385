const isClient = typeof document !== 'undefined' ? true : false

const isServer = !isClient

const cleanUrl = (url: string) => {
    url = url.replace('https://', '')
    url = url.replace('http://', '')
    url = url.replace('www.', '')

    return url
}

const cleanAppFromUrl = (url: string) => {
    const matches = url.match(/\.(.*)/)

    if (Array.isArray(matches) && matches.length) {
        return matches[0]
    }
}

const cleanScopeFromUrl = (url: string) => {
    const cleanHost = cleanUrl(new URL(url).host)

    const host = cleanHost
        .replace(/.(dev|test|local)./g, '.')
        .replace(/(se|localhost)(:\d)/g, '')

    const matches = host.split('.')

    if (Array.isArray(matches) && matches.length) {
        return matches[1]
    }
}

export {
    isClient,
    isServer,
    cleanUrl,
    cleanAppFromUrl,
    cleanScopeFromUrl
}