import { useMemo } from 'react'
import { useMatches } from '@remix-run/react'

const useRouteData = <T = Record<string, unknown>>(id: string): T => {
    const matchingRoutes = useMatches()

    const route = useMemo(() => {
        return matchingRoutes.find((route) => route.id === id)
    }, [matchingRoutes, id])

    return route?.data as T
}

export default useRouteData