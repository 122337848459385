import useRouteData from '../useRouteData'

import type { App } from '~/config/types'

const useApp = (): App => {
    const { app } = useRouteData<{ app: App }>('root')

    return app || {}
}

export default useApp