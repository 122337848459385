import {
    useCallback,
    useState,
    useEffect
} from 'react'

import { isClient } from '~/utils/helpers'
import useUserAgent from '~/hooks/useUserAgent'

import type * as Types from './types'

const useViewSize = (): Types.UseDeviseSizeResponse => {
    const {
        isMobile,
        isTablet
    } = useUserAgent()

    const getSize = useCallback(() => {
        if (isMobile && !isTablet) {
            return 's'
        } else if (isTablet) {
            return 'm'
        } else if (isClient) {
            if (window.innerWidth <= 735) {
                return 's'
            } else if (window.innerWidth <= 1024) {
                return 'm'
            } else {
                return 'l'
            }
        } else {
            return 'l'
        }
    }, [
        isMobile,
        isTablet
    ])

    const [size, setSize] = useState(getSize())

    useEffect(() => {
        const handleOnResize = () => {
            setSize(getSize())
        }

        window.addEventListener('resize', handleOnResize)
    }, [getSize])

    const isSmall = useCallback(() => {
        return size === 's'
    }, [size])

    const isMedium = useCallback(() => {
        return size === 'm'
    }, [size])

    const isLarge = useCallback(() => {
        return size === 'l'
    }, [size])

    const returnedValue = [size, isSmall, isMedium, isLarge] as Types.UseDeviseSizeResponse

    returnedValue.size = returnedValue[0]
    returnedValue.isSmall = returnedValue[1]
    returnedValue.isMedium = returnedValue[2]
    returnedValue.isLarge = returnedValue[3]

    return returnedValue
}

export default useViewSize